import { graphql } from "gatsby"
import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import Select from "react-select"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Subheader from "../components/subheader"

// const html = '<blockquote class="tiktok-embed" cite="https://www.tiktok.com/@furryfritz/video/6879814276299721985" data-video-id="6879814276299721985" data-embed-from="oembed" style="max-width: 605px;min-width: 325px;" > <section> <a target="_blank" title="@furryfritz" href="https://www.tiktok.com/@furryfritz?refer=embed">@furryfritz</a> <p>Catographer at work 📸🐱 <a title="furryfritz" target="_blank" href="https://www.tiktok.com/tag/furryfritz?refer=embed">#FurryFritz</a> <a title="cat" target="_blank" href="https://www.tiktok.com/tag/cat?refer=embed">#cat</a> <a title="britishshorthair" target="_blank" href="https://www.tiktok.com/tag/britishshorthair?refer=embed">#britishshorthair</a> <a title="kitten" target="_blank" href="https://www.tiktok.com/tag/kitten?refer=embed">#kitten</a> <a title="foryou" target="_blank" href="https://www.tiktok.com/tag/foryou?refer=embed">#foryou</a></p> <a target="_blank" title="♬ オリジナル楽曲 - kk - kk" href="https://www.tiktok.com/music/オリジナル楽曲-kk-6840588230807603969?refer=embed">♬ オリジナル楽曲 - kk - kk</a> </section> </blockquote> <script async src="https://www.tiktok.com/embed.js"></script>'
class TokLoader extends React.Component {
  constructor(props) {
    super(props)
    this.ttRef = React.createRef()
    this.state = {
      html: ''
    }

    fetch(`https://www.tiktok.com/oembed?url=${this.props.url}`)
      .then(response => response.json())
      .then(data => {
        // remove tiktok embed.js, we include this file once in the document head.
        // save fetched html to a state var to prevent multiple http requests.
        this.setState({html: data.html.slice(0, -61)})
      })
  }

  componentDidUpdate() {
    // html has been fetched from tt.com and rendered on our page.
    // embed.js probably didn't "hydrate" it or whatever, so do that now.
    // tiktok replaces a <section> with an <iframe>, that's how you know.
    // you must pass an array spread of elements to lib.render, or it errors out.
    const didRender = this.ttRef.current.getElementsByTagName('iframe').length
    console.log('componentDidUpdate', didRender)
    if (!didRender) {
      const awaitttApi = window.setInterval(() => {
        console.log('awaiting TT API')
        if (window && window.tiktokEmbed && window.tiktokEmbed.lib) {
          rendertt()
        }
      }, 100)

      const rendertt = () => {
        console.log('rendering TT')
        if (awaitttApi) {
          clearInterval(awaitttApi)
        }
        if (window && window.tiktokEmbed && window.tiktokEmbed.lib) {
          // TODO: may stop working if tiktok changes their API
          window.tiktokEmbed.lib.render([...this.ttRef.current.getElementsByClassName('tiktok-embed')])
        }
      }
    }
  }

  render() {
    return (
      <div ref={this.ttRef} dangerouslySetInnerHTML={{__html: this.state.html}} />
    )
  }
}


const InspirePage = ({ data }) => {
  const PER_PAGE = 4
  const [selectedOption, setSelectedOption] = useState('')
  const [pageCount, setPageCount] = useState(1)
  const [filteredToks, setFilteredToks] = useState([])
  const [displayedToks, setDisplayedToks] = useState([])
  const selectOptions = data.allStrapiTiktok.nodes.reduce((pre, cur) => {
    const exists = pre.find(el => el.value === cur.category.strapi_id)
    if (!exists) {
      pre.push({
        value: cur.category.strapi_id,
        label: cur.category.name
      })
    }
    return pre
  }, [])
  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent',
      boxShadow: 'none',
      borderColor: state.isActive ? 'grey' : 'white',
      '&:hover': {
        borderColor: 'white'
      }
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: '#1f1f1f',
      border: '1px solid white',
      borderTop: 'none',
      marginTop: '0'
    }),
    option: provided => ({
      ...provided,
      backgroundColor: 'transparent'
    }),
    singleValue: provided => ({
      ...provided,
      color: 'yellow'
    }),
  }

  useEffect(() => {
    const toks = selectedOption.value ? 
      data.allStrapiTiktok.nodes.filter(tok => tok.category.strapi_id === selectedOption.value) : 
      data.allStrapiTiktok.nodes
    setFilteredToks(toks)
    setDisplayedToks(toks.slice(0, (pageCount * PER_PAGE)))
  }, [selectedOption, pageCount, data.allStrapiTiktok.nodes])

  const updateSelected = event => {
    setSelectedOption(event)
    setPageCount(1)
  }

  return (
    <Layout id="inspire">
      <Helmet>
        <title>Inspire | JFK We Choose 2</title>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-D3S544GNMX"></script>
        <script>{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-D3S544GNMX');
        `}</script>
        <script async src="https://www.tiktok.com/embed.js"></script>
      </Helmet>
      <Hero {...data.strapiInspire.hero} />
      <Subheader {...data.strapiInspire.inspire} />
      {/* <section className="check-back">
        The We Choose 2 movement takes off on September 12th. Check here then to see your inspiration in action.
      </section> */}

      <section className="check-back">
        <a href="https://www.tiktok.com/t/ZTR5ksdxe/" target='_blank' rel='noreferrer'>Join the movement</a>
      </section>

      <div className="tt-wrap">
        <div className="tt-select-wrap">
          <Select
            styles={selectStyles}
            defaultValue={selectedOption}
            onChange={updateSelected}
            options={selectOptions}
            isSearchable={false}
            placeholder="Please select a filter"
          />
        </div>

        <div className="tt-tiktoks-wrap">
          {displayedToks.map(tok => <TokLoader key={tok.id} url={tok.tiktok}/>)}
        </div>

        <div className="tt-load-more">
          {filteredToks.length > displayedToks.length &&
            <button onClick={() => setPageCount(pageCount + 1)}>Load More</button>
          }
        </div>
      </div>
    </Layout>
  )
}

export default InspirePage

export const query = graphql`
{
  strapiInspire {
    hero {
      title
      description
      image {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
            )
          }
        }
      }
    }
    inspire {
      title
      subtitle
      copy
    }  
  }
  allStrapiTiktok {
    nodes {
      id
      tiktok
      category {
        name
        strapi_id
      }
    }
  }
}
`;
