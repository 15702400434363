import React from "react"
import ReactMarkdown from "react-markdown"
import PropTypes from "prop-types"
import "./_subheader.scss"

const Subheader = ({ title, subtitle, copy }) => {
  return (
    <div className="subheader">
      <div>
        {title &&
        <ReactMarkdown className="title" children={title} />
        }
        {subtitle &&
        <div className="subtitle">{subtitle}</div>
        }
        {copy &&
        <div className="copy">{copy}</div>
        }
      </div>
    </div>
  )
}
Subheader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  copy: PropTypes.string
}

export default Subheader
